import { packageLocationTypes } from "@/hooks/esim/location-packages/types/locationPackage.types";

export const esimPlansTabs = [
  {
    id: 2,
    title: "Region Plans",
    value: "region-plans",
    locationType: packageLocationTypes.regions
  }
];
