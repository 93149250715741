import { IObjectKeys } from "@/interfaces/IObjectKeys";

export function getNestedValue(path: string, obj: IObjectKeys): any {
  const nestedFields = path.split("."); // ?;
  let val: any = obj;
  let i = 0;

  while (i < nestedFields.length) {
    const key: string = nestedFields[i];

    if (typeof val !== "object") {
      i = nestedFields.length;
      break;
    }

    if (val[key] === undefined) {
      return null;
    }

    if (typeof val === "object" && val[key]) {
      val = val[key];
      i++;
    } else {
      i = nestedFields.length;
    }
  }

  return val;
}
