import moment from "moment";

export function dateConverter(date: Date): string {
  if (isNaN(date.getTime())) {
    return "";
  }

  return moment(date).format("D MMM YYYY [at] HH[:]mm a");
}

export function getFullDate(date: Date): string {
  if (isNaN(date.getTime())) {
    return "";
  }

  return moment(date).format(`DD/MM/yy - HH[:]mm[:]ss`);
}
