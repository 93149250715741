import { ITableHeadCell } from "@/components/Table/ts/interfaces/TableStructure";
import { TPackageLocation } from "@/hooks/esim/location-packages/types/locationPackage.types";

export function generateEsimPlansHead(
  locationType: TPackageLocation,
  currentSort: any,
  currentSortKeyLocationName: string
): ITableHeadCell[] {
  const { byLocationName, byUpdatedAt } = currentSort;
  return [
    {
      size: "xl",
      label: currentSortKeyLocationName,
      id: "location",
      sort: {
        keyName: byLocationName.keyName,
        order: byLocationName.order
      }
    },
    {
      size: "md",
      label: "Active Plans",
      id: "location-packages"
    },
    {
      size: "lg",
      label: "Date updated",
      id: "location-created-date"
    },
    {
      label: "Updated by",
      id: "location-updated-by",
      size: "lg",
      sort: {
        order: byUpdatedAt.order,
        keyName: byUpdatedAt.keyName
      }
    }
  ];
}
